import './sass/index.scss'
import Sidebar from './components/Sidebar';
import Content from './components/Content';
import { Fragment, useEffect } from 'react';
import Email from './components/Email';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import publicIp from 'react-public-ip'
import axios from 'axios';



function App() {

  const getIp = async() => {

    try {
      const ipPu = await publicIp.v4()
      // console.log(ipPu)
      const sendIp = await axios.post('https://api-resumee.vercel.app/api/v1/cn', {
        ip: ipPu
      })
      // console.log(sendIp)
    } catch(err) {
      console.log(err)
    }
  }

  


  useEffect(() => {
    getIp()
  }, [])


  return <Fragment>
      <BrowserRouter>
        <div className='container'>
          <Sidebar />
          <Routes>  
            <Route index element={<Content />}/>
            <Route path='/email' element={<Email />}/>
          </Routes>
        </div>
      </BrowserRouter>
  </Fragment>
}


export default App


