import foto from '../img/Foto.jpeg'
import { HiOutlinePhone } from "react-icons/hi";
import { HiOutlineMail } from "react-icons/hi";
import { HiOutlineMap } from "react-icons/hi";
import Sections from './Sections';
import { languages, aptitudes, courses, skills } from './Info';
import { NavLink } from 'react-router-dom';



function Sidebar() {

  return (
    <div className="sidebar">
      <div className="sidebar__picture">
        <div className="triangle"></div>
        <figure className='image'>
          <img src={foto} alt="Giovani" />
        </figure>
      </div>
      <div className="sidebar__inf">
        <h3 className='text-center'>Giovani Gómez Pasillas</h3>
        <div className="personal-inf">
          <div className="phone">
            <HiOutlinePhone className='icon' />
            <span className='text1'>3317831481</span>
          </div>
          <div className="email">
            <HiOutlineMail className='icon' />
            <NavLink to='/email' className='text1 markEmail'>  
              giovani-gomez1@hotmail.com
            </NavLink>
          </div>
          <div className="address">
            <HiOutlineMap className='icon' />
            <span className='text1'>Agustin Olachea #415, neighborhod Constirución, Zapopan Jal. C.P 45180</span>
          </div>
        </div>
        <div className="sections">
          <Sections title={'Languages'} content={languages}/>
          <Sections title={'Aptitudes'} content={aptitudes}/>
          <Sections title={'Skills'} content={skills}/>
          <Sections title={'Courses'} content={courses}/>

        </div>
      </div>

    </div>
  )
}


export default Sidebar