import { Fragment } from "react"

function Sections({title, content}) {
  return (
    <div className="section">
      <span>{title}</span>
      <ul className="list">
        {
          content.map((ele, ind) => {
            return <Fragment key={ind}>
              <li>{ele}</li>      
            </Fragment>
          })
        }
        
      </ul>
    </div>
  )
}


export default Sections