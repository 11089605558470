export const languages = [
  'Spanish (Native)',
  'English (70%)'
]

export const aptitudes = [
  'Team work', 
  'Problem solving',
  'Honesty',
  'Punctuality', 
  'Fast learning',
  'Detail oriented'
]

export const courses = [
  'Bootcam Front End Javascript (120hrs)', 
  'Back End Node Js (120hrs)'
]

export const skills = [
  'Html 5', 
  'Css 3', 
  'Sass', 
  'Javascript', 
  'React Js', 
  'Node Js', 
  'Mongodb'
]


export const job_experience = [
  {
    date: '08/2023 - Current', 
    place: 'Alorica - Support Agent', 
    activity: 'Support users of a spend and expense platform via chat, phone and email. Following up tickets, help configure settings and troubleshooting.'
  },
  {
    date: '10/2021 - 01/2023', 
    place: 'Tata - Chat Support', 
    activity: 'Help customers place online orders, process refunds, re-orders, returns.Manage user accounts, basic troubleshooting.'
  },
  {
    date: '07/2020 - 08/2021', 
    place: 'Cognizant - Customer service', 
    activity: 'Process orders and assist clients by phone, follow up on cases, solve technical and basic problems, process refunds, reorder items and follow up on orders through courier services.'
  },
  {
    date: '03/2011 - 11/2018', 
    place: 'Eesp - Field Engineer', 
    activity: 'Installation and maintenance of electronic security equipment, voice and data networks, audio and video. Planning, design and installation of automation in residences integrating lighting, security, audio, video and connectivity.'
  }
]

export const education = [
  {
    date: '2002 - 2006',
    place: 'Ceti Colomos', 
    activity: 'Computer Electronics Engineering.'
  },
  {
    date: '2000 - 2001',
    place: 'Grupo Técnologico Electrón', 
    activity: 'Maintenance, installation and repair of computers and Network intallations.'
  },
  {
    date: '1996 - 2000',
    place: 'Politecnico Belenes', 
    activity: 'Profesional Technician informatics.'
  }
]