function Element({date, place, activities}) {
  return (
    <div className="content__sect-job">
      <div className="content__sect-job--date-place">
        <span className='text2'>{date}</span>
        <span className='text2'>{place}</span>
      </div>
      <div className="content__sect-job--activities">
        <span className='text1'>{activities}</span>
      </div>
    </div>
  )
}


export default Element