import { job_experience, education } from "./Info"

import Block from "./Block"


function Content() {
  return (
    <div className="content">
      <div className="about">
        <div>
          <h3>About me</h3>
        </div>
        <h1>Computer engeener <span className='text2'>
             with more than 5 years of experience in installing 
            security and automation systems, fixing photocopiers and printers, and 
            customer service.
          </span>
        </h1>
      </div>
      <Block list={job_experience} title={'Profesional Experience'}/>
      <Block list={education} title={'Education'}/>
      {/* <div className="content__sect">
        <div>
          <h3 className='text3'>Profesional Experience</h3>
        </div>
        {
          job_experience.map((item, idx) => {
            return <Element key={idx} date={item.date} place={item.place} activities={item.activity} />
          })
        }
      </div>
      <div className="content__sect">
        <div>
          <h3 className='text3'>Education</h3>
        </div>
        {
          education.map((item, idx) => {
            return <Element key={idx} date={item.date} place={item.place} activities={item.carreer} />
          })
        }
      </div> */}

    </div>
  )
}

export default Content