import Element from "./Element"


function Block({list, title}) {
  return  <div className="content__sect">
  <div>
    <h3 className='text3'>{title}</h3>
  </div>
  {
    list.map((item, idx) => {
      return <Element key={idx} date={item.date} place={item.place} activities={item.activity} />
    })
  }
</div>
}

export default Block